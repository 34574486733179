<template>
  <div class="container">
    <div class="scroll_tips">
      <ul class="scroll_box">
        <li class="scroll_item">176****6142 已订购权益包</li>
        <li class="scroll_item">131****7394 已订购权益包</li>
        <li class="scroll_item">155****4549 已订购权益包</li>
        <li class="scroll_item">130****5436 已订购权益包</li>
        <li class="scroll_item">156****0054 已订购权益包</li>
        <li class="scroll_item">171****5245 已订购权益包</li>
        <li class="scroll_item">175****6540 已订购权益包</li>
        <li class="scroll_item">185****0974 已订购权益包</li>
      </ul>
    </div>
    <div class="pendant-wrap">
      <div
        class="link"
        @click="
          handleRoute('/guangtong_equity_center/uLifeRed/privilegeLanding')
        " 
      >
        权益领取说明
      </div>
      <div
        class="link"
        @click="
          handleRoute('/guangtong_equity_center/uLifeRed/customerService')
        "
      >
        联系在线客服
      </div>
    </div>
    <div class="form">
     
      <div :class="['content', { fixed: scrollTop > 600 }]">
        <img
          class="step1"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/ydbj2_02.gif"
        />
        <van-field
          v-model="form.phone_number"
          type="tel"
          maxlength="11"
          placeholder="请输入您的手机号码"
        />
        <img
          @click="onSubmit"
          class="submit"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/submit_lijidinggou.png"
        />
      </div>
      <div class="checked">
        <div class="price-text">首月29.9元,次月起49.9元</div>
        <div class="box">
          <img
            v-show="!checked"
            @click="checked = true"
            class="checkbox"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/checkbox-default.png"
          />
          <img
            v-show="checked"
            @click="checked = false"
            class="checkbox"
            src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/checkbox-checked.png"
          />
          <span class="agreement-text">我已阅读并同意</span>
          <span
            class="book"
            @click="
              handleRoute('/guangtong_equity_center/uLifeRed/userAgreement')
            "
            >《用户协议》</span
          >
          <span class="book" @click="handleScollBottom">《订购规则》</span>
        </div>
      </div>
    </div>
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/card1_1.png"
    />
    <img
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/card2.png"
    />
    <img
      ref="guize"
      class="card"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/card3.png"
    />
    <div class="info">
      <div class="text title" >【订购须知】</div>
      <div class="text">
        1、本页面仅供客户购买权益，老年及未成年人请谨慎消费，敬请知悉！
      </div>
      <div class="text">
        2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗
      </div>

      <div class="text title">
        【福利详情】：热门会员权益*2+10元支付宝生活缴费券*2+10元超市优惠券(天猫超市/盒马生鲜/永辉超市,三选一)*3
      </div>
      <div class="text">
        【会员使用说明】：视听会员权益如腾讯爱奇艺通过订购手机号登陆对应平台即可使用
      </div>
      <div class="text">【10元生活缴费券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效,红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为生活缴费，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于100元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取2次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户
      </div>
      <div class="text">【10元天猫超市优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效,红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为天猫超市，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户
      </div>
      <div class="text">【10元盒马生鲜优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效,红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为盒马生鲜，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户
      </div>
      <div class="text">【10元永辉超市优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效,红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为永辉超市，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户
      </div>

      <div class="text title">【注意事项】</div>
      <div class="text">1、会员权益每个计费周期（30天）限领1次，逾期作废。</div>
      <div class="text">
        2、支付宝端的指定银行及卡类型活动,仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内仅能参加1次。同一支付宝账号、手机号、身份证、手机终端,符合以上任一条件均视为同一用户。
      </div>
      <div class="text">
        3、为确保活动公平性,如用户出现不正当行为(如虚假交易、作弊、恶意套取现金、刷信等),银行有权撤销用户优惠资格,并有权收回用户获得的福利。
      </div>
      <div class="text title">【退费说明】</div>
      <div class="text">
        1、退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后30天内不能再次订购;
      </div>
      <div class="text">
        2、退订路径:请登录【支付宝】，点击右下角【我的】-【设置】-【支付设置】-【免密支付/自动扣款】在自动扣款界面，找到要取消自动续费的服务，点击【服务】。在服务详情界面，点击最下方【关闭服务】。出现选项，点击【关闭服务】，即可成功退订本产品;
      </div>
      <div class="text">
        3、如对活动有疑问或更多需求，请咨询在线客服或拨打4000675108;
      </div>
    </div>
  </div>
</template>

<script>
import { alipayCallbackPayAPI } from "@/api/guangtong_equity_center.js";
import { generateSign } from "@/utils/common";

export default {
  data() {
    return {
      form: {
        phone_number: "",
      },
      scrollTop: "",
      checked: false,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", that.handlerscroll);
  },
  methods: {
    async onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!this.form.phone_number) {
        this.$toast("请输入手机号");
      } else if (!filterPhone.test(phone_number)) {
        this.$toast("请输入正确的手机号码");
      } else if (!this.checked) {
        this.$toast("请阅读并同意《用户协议》《订购规则》");
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await alipayCallbackPayAPI({
          phone_number,
          item_name: "优生活会员",
          callback_name: "巨量",
          callback: location.search,
          auth: generateSign("QIUMOGT", "NBO(P*&GFAS"),
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = res.sign_str;
        }
      }
    },
    handlerscroll() {
      this.scrollTop =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    handleScollBottom() {
      this.$nextTick(() => {
        const top = this.$refs.guize.offsetTop;
        document.documentElement.scrollTop = document.body.scrollTop = top;
      });
    },
    handleRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #e72322
    url("https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLifeRed/bg.png")
    100% -124px / contain no-repeat;
  position: relative;
  padding: 770px 0 500px;

  .scroll_tips {
    width: 100%;
    margin: 0 auto 0;
    overflow: hidden;

    .scroll_box {
      height: 61px;
      animation: scroll 15s linear infinite;
      display: flex;

      .scroll_item {
        background-color: rgba(0, 0, 0, 0.44);
        border-radius: 40px;
        white-space: nowrap;
        width: 329px;
        text-align: center;
        font-weight: 500;
        flex-shrink: 0;
        line-height: 61px;
        font-size: 24px;
        color: white;
        margin-left: 62px;
      }

      @keyframes scroll {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-317.2%);
        }
      }
    }
  }
  .pendant-wrap {
    position: absolute;
    top: 20px;
    right: 0;

    .link {
      height: 40px;
      line-height: 40px;
      
      padding:0 9px 0 18px;
      color: #fff;
      font-weight: 400;
      font-size: 24px;
      background-color: rgb(0, 0, 0, 0.4);
      border-radius: 20px 0 0 20px;

      &:last-child {
        margin-top: 12px;
      }
    }
  }

  .card {
    display: block;
    margin: 47px auto 0;
    width: 690px;
  }

  .form {
    padding-top: 52px;
   
    .content {
      position: relative;
      .step1 {
        width: 300px;
        position: absolute;
        z-index: 1;
        top: -70px;
        left: 80px;
        pointer-events: none;
      }
      &.fixed {
        position: fixed;
        bottom: 50px;
        left: 0;
        right: 0;
      }

      ::v-deep .van-cell {
        width: 650px;
        height: 126px !important;
        background-color: #ffffff;
        border-radius: 70px;
        margin: 0px auto 0;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 0 0 23px;

        .van-field__body {
          height: 100%;
        }

        .van-field__control {
          font-size: 40px;
          text-align: center;
          &::-webkit-input-placeholder {
            font-size: 40px;
            color: #666666;
            text-align: center;
          }
        }
      }

      .submit {
        width: 652px;
        display: block;
        margin: 19px auto 0;
        animation: scales 1s ease-in-out infinite;

        @keyframes scales {
          0% {
            transform: scale(0.8);
          }
          50% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.8);
          }
        }
      }
    }

    .checked {
      font-size: 26px;
      color: #ffdada;
      margin-bottom: 74px;
      .price-text {
        transform: translateY(-40px);
        text-align: center;
        font-weight: 400;
      }

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: 400;
        }

        .checkbox {
          flex-shrink: 0;
          height: 34px;
          width: 34px;
          margin-right: 16px;
        }
      }
    }
  }

  .info {
    padding: 0 30px;
    .text {
      line-height: 46px;
      color: #fcf5e7;
      font-size: 26px;
      font-weight: 500;
      word-break: break-all;
    }

    .title {
      padding-top: 60px;
    }
  }
}
</style>